import React from "react";
import Header from "../components/header/header";
import Footer from "../components/footer/footer";
import PrivacyPolicyContent from "../components/privacy-policy/privacy-policy";
import {Helmet} from "react-helmet";

import SocialImage from '../images/social/LogoNeu.jpg';

export default class PrivacyPolicy extends React.Component {
    render() {
        return (
            <>
                <Helmet htmlAttributes={{"lang": "de"}}>
                    {/*Primary Meta Tags*/}
                    <title>Kronschl&auml;ger Reisen — Datenschutz</title>
                    <meta name="title" content="Kronschl&auml;ger Reisen — Datenschutz"/>
                    <meta name="description" content="Kurze Beschreibung, 170 Zeichen"/>

                    {/*Open Graph / Facebook */}
                    <meta property="og:type" content="website"/>
                    <meta property="og:url" content="https://www.kronschlaeger-reisen.at"/>
                    <meta property="og:title" content="Kronschl&auml;ger Reisen — Datenschutz"/>
                    <meta property="og:description" content="Same as above"/>
                    <meta property="og:image" content={`https://www.kronschlaeger-reisen.at${SocialImage}`}/>

                    {/* Google Bot */}
                    <meta name="robots" content="noindex,follow"/>
                </Helmet>
                <Header/>
                <PrivacyPolicyContent/>
                <Footer/>
            </>
        );
    }
}