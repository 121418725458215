import React from "react";
import * as styles from './privacy-policy.module.css';

export default class PrivacyPolicyContent extends React.Component {

    render() {
        return (
            <div className={"container " + styles.padding}>
                <h1>Datenschutzerklärung</h1>
                <h2>Erklärung zur Informationspflicht</h2>
                <p>Der Schutz Ihrer persönlichen Daten ist uns ein besonderes Anliegen. Wir verarbeiten Ihre Daten daher
                    ausschließlich auf Grundlage der gesetzlichen Bestimmungen (DSGVO, TKG 2003). In diesen
                    Datenschutzinformationen informieren wir Sie über die wichtigsten Aspekte der Datenverarbeitung im
                    Rahmen unserer Website. </p>
                <p>
                    Beim Besuch unserer Webseite wird Ihre IP-Adresse, Beginn und Ende der Sitzung für die Dauer dieser
                    Sitzung erfasst. Dies ist technisch bedingt und stellt damit ein berechtigtes Interesse iSv Art 6
                    Abs 1 lit f DSGVO dar. Soweit im Folgenden nichts anderes geregelt wird, werden diese Daten von uns
                    nicht weiterverarbeitet.
                </p>
                <h2>Kontakt mit uns</h2>
                <p>
                    Wenn Sie per Formular auf der Website oder per E-Mail Kontakt mit uns aufnehmen, werden Ihre
                    angegebenen Daten zwecks Bearbeitung der Anfrage und für den Fall von Anschlussfragen sechs Monate
                    bei uns gespeichert. Diese Daten geben wir nicht ohne Ihre Einwilligung weiter.
                </p>
                <h2>Web-Analyse</h2>
                <p>
                    Unsere Website verwendet Funktionen des Webanalysedienstes SimpleAnalytics.com aus Niederlanden.
                    Es werden dazu keine Cookies verwendet. Die dadurch erzeugten Informationen werden auf den Server
                    des Anbieters übertragen und dort nur Informationen ohne Personenbezug gespeichert.
                </p>
                <p>
                    Die Datenverarbeitung erfolgt auf Basis der gesetzlichen Bestimmungen des § 96 Abs 3 TKG sowie des
                    Art 6 Abs 1 lit a (Einwilligung) und/oder f (berechtigtes Interesse) der DSGVO.
                </p>
                <p>
                    Unser Anliegen im Sinne der DSGVO (berechtigtes Interesse) ist die Verbesserung unseres Angebotes
                    und unseres Webauftritts. Da uns die Privatsphäre unserer Nutzer wichtig ist, werden keine
                    Nutzerdaten
                    (auch nicht pseudonymisiert verarbeitet oder gespeichert.
                </p>
                <h2>Ihre Rechte</h2>
                <p>
                    Ihnen stehen bezüglich Ihrer bei uns gespeicherten Daten grundsätzlich die Rechte auf Auskunft,
                    Berichtigung, Löschung, Einschränkung, Datenübertragbarkeit, Widerruf und Widerspruch zu. Wenn Sie
                    glauben, dass die Verarbeitung Ihrer Daten gegen das Datenschutzrecht verstößt oder Ihre
                    datenschutzrechtlichen Ansprüche sonst in einer Weise verletzt worden sind, können Sie sich bei der
                    uns info@kronschlaeger-reisen.at oder der Datenschutzbehörde beschweren.
                </p>
                <h2>Sie erreichen uns unter folgenden Kontaktdaten:</h2>
                <p>
                    <addrss>
                        Erwin Kronschläger - Kronschläger Reisen<br/>
                        Kapping 4<br/>
                        4723 Natternbach<br/>
                        Österreich<br/>
                        Email: info@kronschlaeger-reisen.at<br/>
                        Telefon: 0664 5030634
                    </addrss>
                </p>
            </div>
        );
    }
}